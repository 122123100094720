import React from "react"

import Layout from "../components/Layout"
import { HeroSimpleCentered } from '../components/Global/Hero'
import SEO from "../components/Global/SEO"

const NotFoundPage = () => (
  <Layout>
    <SEO
      title="404 - Page Not Found"
    />
    <HeroSimpleCentered>
      <h2 className="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
        404 <br className="xl:hidden" /><span className="text-yellow-300"> Not Found</span>
      </h2>
      <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
        You just hit a page that doesn&#39;t exist... the sadness.
      </p>
    </HeroSimpleCentered>
  </Layout>
)

export default NotFoundPage
